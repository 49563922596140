import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link
} from "react-router-dom";
import { collectionsService } from '../../services/collections';
import { uiOperations } from "../../redux/ui"

import ErrorBoundary from "../common/errorboundary";
import SelectPopover from "../common/selectPopover";
import LoaderFancy from '../common/loaderfancy.jsx';
import ErrorBar from "../common/errorBar";
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"

import MakeReviews from './productMakeReviews';

import { capitalizeFirst } from '../../utils/text';


const AudienceProductCategory = ({
  collection,
  match,
  history,
  categories,
  subreddits,
  currentUser,
  onClose, // mobile view only
}) => {
  const { id } = match.params;
  const category = categories.filter(t => t.id === parseInt(id))[0];
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer
  const hasProductsTab = collection && currentUser && currentUser.features.audience_products; // actual content behind paywall

  // effects
  useEffect(() => {
    if (hasProductsTab && (!categoryDetails || categoryDetails.id !== id)){
      setCategoryDetails(null);
      fetchCollectionCategory(id);
    }
  }, [id]);

  const fetchCollectionCategory = (t) => {
    setCategoryDetails(null)
    setError(null)
    collectionsService.getCollectionProductCategory(collection.hash, id, (results) => {
      if (results && results.data){
        setCategoryDetails(results.data)
        setLoadingCategory(false)
      }
    }, (response, error) => {
      setError(error);
    })

    if (!isMobile && document.getElementById('main').scrollTop > 250){
      document.getElementById("category-detail") && document.getElementById("category-detail").scrollIntoView({behavior: 'smooth'});
    }
  }

  if (!category) return '' // haven't loaded category list yet

  // organize reviews by make (with nested models)
  var productMakes = [];
  (categoryDetails && categoryDetails.products || []).filter(p => p.make).forEach(p => {
    var makeIndex = productMakes.map(m => m.make).indexOf(p.make);
    if (makeIndex === -1){
      productMakes.push({
        'make': p.make,
        'products': [], // only store products that have a model here
        'reviews': []
      });
      makeIndex = productMakes.length - 1;
    }
    // productMakes[makeIndex].reviews = [...productMakes[makeIndex].reviews, ...p.products_productreviews]
    //   .sort((a,b) => (a.reddit_comment_data.timestamp_utc < b.reddit_comment_data.timestamp_utc) ? 1 : ((b.reddit_comment_data.timestamp_utc < a.reddit_comment_data.timestamp_utc) ? -1 : 0))
    //   .sort((a,b) => (a.one_to_five_rating < b.one_to_five_rating) ? 1 : ((b.one_to_five_rating < a.one_to_five_rating) ? -1 : 0))
    productMakes[makeIndex].reviews = [...productMakes[makeIndex].reviews, ...p.reviews]
    if (p.model){
      productMakes[makeIndex].products.push(p)
    }
  });

  // count up sum stars and average stars for each make
  productMakes.forEach(m => {
    m.stars_sum = m.reviews.reduce((a,b) => a + b.one_to_five_rating, 0)
    m.positive_stars_sum = m.reviews.filter(r => r.one_to_five_rating > 3).reduce((a,b) => a + b.one_to_five_rating, 0)
    m.stars_average = (m.stars_sum / m.reviews.length).toFixed(1)
  })

  // order by number of stars (first all, then positive)
  productMakes = productMakes.sort((a,b) => (a.stars_sum < b.stars_sum) ? 1 : ((b.stars_sum < a.stars_sum) ? -1 : 0))
  productMakes = productMakes.sort((a,b) => (a.positive_stars_sum < b.positive_stars_sum) ? 1 : ((b.positive_stars_sum < a.positive_stars_sum) ? -1 : 0))

  // console.log(categoryDetails)
  // console.log(productMakes)

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Product Category</title></Helmet>
      
      <div className="">
        {onClose ? (
          <div className="mr-2 mb-2 opacity-50 hover:opacity-100"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>

          </div>
        ) : ''}          
        
        <FeaturePaywallWrapper featureKey="products" currentUser={currentUser}>
          {!categoryDetails ? (
            <div className="h-96 relative">
              <LoaderFancy height={300}/>
            </div>
          ) : (
            <div className={``}>
              <div className="mx-auto p-2 px-3 text-semibold bg-gray-800 text-gray-400 rounded-md mb-4 flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 mr-2 text-yellow-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>
                This feature is very new & being improved. If you have feedback, please <a href="mailto:fed@gummysearch.com" className="underline ml-1 inline">reach out</a>!
              </div>
              {/*<div className="mt-4 mb-2 flex items-center">
                <h3 className="flex-1 flex text-sm items-center">
                  <span className="text-gray-300 font-semibold">About {capitalizeFirst(category.name)}</span>
                </h3>
              </div>

              <div className="p-6 bg-gray-800 rounded-lg text-white">

                <div className="flex border-b border-gray-500 pb-4 mb-4">
                  <h2 className="text-xl font-medium flex items-center flex-wrap mr-2">
                    <span>{capitalizeFirst(category.name)}</span>
                  </h2>
                </div>
                
                <div>
                  <div className="text-lg whitespace-pre-wrap">summary goes here</div>
                </div>

                <div className="mt-4 flex">
                  <Link
                    // to={`/reddit/?type=submissions&collection=${collection.hash}&topic=${name}&sort=top`}
                    to={`/audience/${collection.hash}/invalidurl/`}
                    type="button"
                    className="ml-auto cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50 hidden sm:block">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    Browse all posts
                  </Link>
                </div>
              </div>*/}
            </div>
          )}

          {categoryDetails ? (
            <div className="">
              <h3 className="flex text-sm items-center w-full">
                <div className="text-gray-300 font-semibold flex items-center">
                  {capitalizeFirst(category.name)} options
                  <p className="ml-2 text-gray-500 truncate">
                    <span>{productMakes.length}</span>
                  </p>
                </div>
              </h3>
              <div className="mt-2 space-y-2">
                {productMakes.map((m , i) => (
                  <MakeReviews key={m.make} make={m} index={i}/>
                ))}
              </div>
            </div>
          ) : ''}
        </FeaturePaywallWrapper>
      </div>

      {/*<Switch>
        {topic ? (
          <ModalRoute component={AudienceTopicResultsDrawer}
            path={`/audience/${collection.hash}/topics/${name}/results/`}
            parentPath={`/audience/${collection.hash}/topics/${name}/`}
            props={{
              'collection': collection,
              'currentUser': currentUser,
              'baseUrl': `/audience/${collection.hash}/topics/${name}/`,
              'topic': topic,
            }}
          />
        ) : ''}
      </Switch>*/}

      {error ? (
        <ErrorBar error={error} setError={setError} onRetry={() => {fetchCollectionCategory(id)}}/>
      ) : ''}
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    // topicSort,
  } = state.ui;

  return {
    currentUser,
    // topicSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceProductCategory);


import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch, Route
} from "react-router-dom";

import { collectionsService } from '../../services/collections';
import { uiOperations } from "../../redux/ui"
// import { hasStarter } from "../../utils/account"

import DrawerRoute from "../common/drawerroute";
import ErrorBoundary from "../common/errorboundary";
import PropsRoute from "../common/propsroute";
import SelectPopover from "../common/selectPopover";
import AudienceProductCategory from "./audienceProductCategory";

import { capitalizeFirst } from '../../utils/text';


// renders list of product categories that are discussed in these subreddits
const AudienceProducts = ({
  collection,
  subreddits,
  currentUser,
  history,
  categoriesSort,
  setUiState,
  categories,
  loadingCategories,
  fetchProductCategories,
}) => {
  // state
  var sortOptions = ['Reviews', 'Products', 'Positive', 'Negative'];
  const sortOption = categoriesSort || sortOptions[0];
  const [keyword, setKeyword] = useState('');
  const [error, setError] = useState('');
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer

  useEffect(() => {
    // navigate to first one if not on mobile and not currently on a product category page
    if (!isMobile && categories && categories.length && !history.location.pathname !== `/audience/${collection.hash}/products/`){
      history.push(`/audience/${collection.hash}/products/${categories[0].id}/`)
    }
  }, [categories])

  // sort & filter
  var sortedItems = JSON.parse(JSON.stringify(categories)); // this clones for new reference
  if (keyword){
    sortedItems = sortedItems.filter(t => t.name.toLowerCase().includes(keyword.toLowerCase()))
  }
  if (sortOption === "Reviews"){
    sortedItems = sortedItems.sort((a,b) => (a.review_count < b.review_count) ? 1 : ((b.review_count < a.review_count) ? -1 : 0));
  } else if (sortOption === "Products"){
    sortedItems = sortedItems.sort((a,b) => (a.product_count < b.product_count) ? 1 : ((b.product_count < a.product_count) ? -1 : 0));
  } else if (sortOption === "Positive"){
    sortedItems = sortedItems.sort((a,b) => (a.ratings[5] / a.review_count < b.ratings[5] / b.review_count) ? 1 : ((b.ratings[5] / b.review_count < a.ratings[5] / a.review_count) ? -1 : 0));
  } else if (sortOption === "Negative"){
    sortedItems = sortedItems.sort((a,b) => (a.ratings[1] / a.review_count < b.ratings[1] / b.review_count) ? 1 : ((b.ratings[1] / b.review_count < a.ratings[1] / a.review_count) ? -1 : 0));
  }

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Products</title></Helmet>

      <div className="mt-4 flex relative">
        <div className={`${isMobile ? 'w-full' : 'w-1/2 pr-8'} flex-shrink-0`}>

          {categories.length !== 0 ? (
            <div className="relative text-gray-400 focus-within:text-white mb-4">
              <form onSubmit={(e) => {e.preventDefault()}}>
                <input id="keyword-search" type="text" placeholder="Search product categories" autoComplete="off" spellCheck="off" autoFocus="on"
                  className="text-sm sm:text-base block bg-gray-800 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent focus:ring-0"
                  // disabled={!collection}
                  value={keyword} onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
                {keyword ? (
                  <div className="absolute inset-y-0 right-0 flex items-center justify-center pr-4 opacity-50 hover:opacity-75 cursor-pointer"
                    onClick={() => setKeyword('')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                ) : ''}
                
              </form>
            </div>
          ) : ''}
          
          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Product Categories</span>

              <p className="ml-2 text-gray-500 truncate">
                {loadingCategories ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : keyword ? (
                  <span>{sortedItems.length} / {categories.length}</span>
                ) : (
                  <span>{categories.length}</span>
                )}
              </p>
            </h2>
            <div className="ml-auto flex">
              {categories.length >= 2 ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Sort by</div>
                  <div className="ml-2 flex items-center">
                    <SelectPopover
                      options={sortOptions}
                      currentOption={sortOption}
                      setCurrentOption={(s) => setUiState('categoriesSort', s)}
                      labelField={undefined}
                    />
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
          
          <div className={`grid grid-cols-1 bg-gray-800 rounded-md overflow-hidden`}>

            {sortedItems.map(category => (
             <Link to={`/audience/${collection.hash}/products/${category.id}/`} key={category.id} 
                className={`group border-b border-gray-700 text-white p-3 sm:p-4 ${history.location.pathname.includes(`/audience/${collection.hash}/products/${category.id}/`) ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}
             >
              <div className="flex items-center justify-between">
                <div className="w-2/3">
                  <h3 className="text-base sm:text-xl font-semibold flex items-center mb-0">
                    <div className="text-nowrap overflow-hidden truncate">{capitalizeFirst(category.name)}</div>

                    <div className="hidden sm:block text-nowrap flex-shrink-0 ml-auto mr-4 text-sm flex items-center text-gray-400">
                        {category.product_count} product{category.product_count !== 1 ? 's' : ''}
                    </div>
                  </h3>
                </div>
                <div className="w-1/3 mt-1 lb:mt-0">
                  <div className="mt-auto w-full text-xs font-medium items-center">
                    <div className="flex flex-grow bg-white rounded-sm relative flex-shrink-0 h-6 mr-4 overflow-hidden">
                      {[5,4,3,2,1].map((rating) => (
                        <div key={rating} className="bg-cyan-700 group-hover:bg-cyan-600 h-full"
                          style={{
                            width: `${(category.ratings[rating] / category.review_count) * 100}%`,
                            backgroundColor: {1: '#e74c3c', 2: '#e74c3c90', 3: '#bdc3c7', 4: '#27ae6090', 5: '#27ae60'}[rating]
                          }}
                        ></div>
                      ))}
                      <div className="absolute p-1 px-2 text-gray-300 max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                        <span className="text-gray-100 mr-1"><b>{category.review_count} review{category.review_count !== 1 ? 's' : ''}</b></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </Link>
            ))}

            {categories.length === 0 && !loadingCategories ? (
              <div className="p-4 opacity-50">
                No products for this audience yet. Will be coming soon. Try a curated audience for now.

                {/*<span className="underline ml-2 cursor-pointer">TODO: fetch them</span>*/}
              </div>
            ) : ''}
          </div>
        </div>

        {!isMobile ? (
          <div id="category-detail" className="w-1/2 flex-shrink-0">
            <PropsRoute path={`/audience/${collection.hash}/products/:id/`} component={AudienceProductCategory}
              collection={collection} categories={categories} loadingCategories={loadingCategories}
              subreddits={subreddits || []}
            />
          </div>
        ) : (
          <Switch>
            <Route path={`/audience/${collection.hash}/products/:id/`}
              render={(props) => {
                if (loadingCategories) return ''; // just while loading
                return (
                  <DrawerRoute closeUrl={`/audience/${collection.hash}/products/`} {...props}>
                    <div id="topic-drawer" className="h-full flex flex-col bg-gray-900 text-white shadow-xl overflow-auto">
                      <div className="p-4 sm:p-6">
                        <PropsRoute path={`/audience/${collection.hash}/products/:id/`} component={AudienceProductCategory}
                          collection={collection} categories={categories} loadingCategories={loadingCategories}
                          subreddits={subreddits || []}
                          match={props.match} history={history} onClose={() => history.push(`/audience/${collection.hash}/products/`)}
                        />
                      </div>
                    </div>
                  </DrawerRoute>
                );
              }}
            />
          </Switch>
        )}

        
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    categoriesSort,
  } = state.ui;

  return {
    currentUser,
    categoriesSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceProducts);


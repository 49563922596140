import React, { useState } from "react"

import { capitalizeFirst, styleKeyword } from '../../utils/text';
import StarRating from './productStarRating.jsx';

const MakeReviews = ({make, index}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modelFilter, setModelFilter] = useState(null);
  const numberOfHighlightsToShow = 10;

  const toggleMake = (m) => {
    setIsOpen(!isOpen)
  }  

  const rankingColors = {
    1: '#FFD700',
    2: '#C0C0C0',
    3: '#CD7F32',
  }

  var reviewsToShow = make.reviews;
  if (modelFilter) {
    reviewsToShow = make.reviews.filter(r => r.model === modelFilter);
  }

  const productsToShow = 2;
  var productsSubtitle = make.products.length > 0 ? `${make.products.slice(0, productsToShow).map(p => p.model).join(', ')} ${make.products.length > productsToShow ? `and ${make.products.length - productsToShow} more` : ''}` : '';

  return (
    <div key={make.make} id={`make-${make.make}`} className={`p-4 rounded-md group bg-gray-800 ${isOpen ? 'bg-gray-700' : 'hover:bg-gray-700 cursor-pointer'}`}
      onClick={() => {
        if (!isOpen){setIsOpen(true)};
      }}
    >
      <div className="flex items-center ">
        <div className="w-8 h-8 mr-1 text-center flex items-center">
          <span className={`text-gray-600 font-bold mx-auto ${index < 3 ? 'text-3xl' : 'text-xl'}`} style={{color: rankingColors[index + 1]}}>
            <span className={`${index < 3 ? 'text-xl' : 'text-base'}`}>#</span>{index + 1}
          </span>
        </div>
        <div className={`h-8 mr-2 flex-shrink-0  ${isOpen ? 'text-gray-300 cursor-pointer hover:text-gray-200' : 'text-gray-500 hover:text-gray-300'}`}
          onClick={() => {
            if (isOpen){toggleMake(make.make)};
          }}
        >
          {isOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          )}
        </div>

        <h3>
          <span className={`font-bold text-lg text-nowrap overflow-ellipsis truncate overflow-hidden ${isOpen ? 'cursor-pointer' : ''}`}
            onClick={() => {
              if (isOpen){toggleMake(make.make)};
            }}
          >
            <span className="hover:underline">{make.make}</span>

            {productsSubtitle ? (
              <span className="text-gray-500 font-normal text-sm ml-2">{productsSubtitle}</span>
            ) : ''}
          </span>
        </h3>

        
        <div className="ml-auto flex items-center space-x-2">
          <div className={`text-white font-semibold`}>{make.stars_average}</div>
          <StarRating rating={make.stars_average} />
          <div className={`w-8 text-center text-gray-400 font-semibold`}>({make.reviews.length})</div>
        </div>
      </div>

      <div className="mt-1 pl-16 ml-1">
        {isOpen ? (
          <div>
            {/*{make.products.length > 0 ? (
               <div className="text-sm flex space-x-2 mb-2">
                {make.products.map(p => (
                  <div key={p.id} className={`px-2 py-1 rounded-md text-gray-200 flex items-center cursor-pointer ${modelFilter === p.model ? 'bg-gray-400 text-gray-800' : 'bg-gray-700 hover:bg-gray-600'}`}
                    onClick={() => setModelFilter(p.model)}
                  >
                    {p.model} <span className="font-semibold opacity-50 ml-2">{p.review_count}</span>
                  </div>
                ))}
                <div className={`px-2 py-1 rounded-md text-gray-200 flex items-center cursor-pointer ${modelFilter === null ? 'bg-gray-400 text-gray-800' : 'bg-gray-700 hover:bg-gray-600'}`}
                  onClick={() => setModelFilter(null)}
                >
                  All
                </div>
              </div>
            ) : ''}*/}
            <div className="space-y-8">
              {reviewsToShow.map(pr => (
                <div key={pr.id} className="space-y-2">
                  {/*<div className="flex items-center">
                    <div className="ml-2 text-gray-400">{capitalizeFirst(pr.highlight)}</div>
                  </div>*/}

                  <div className="text-gray-400 flex gap-x-1 items-center text-sm">
                    <StarRating rating={pr.one_to_five_rating} />
                    <span>on</span>
                    <a href={`https://reddit.com${pr.reddit_comment_data.link}`} target="_blank" className="text-gray-400 hover:underline">{pr.reddit_comment_data.timestamp_readable}</a>
                    <span>in</span>
                    <a href={`https://reddit.com/${pr.reddit_submission_data.subreddit_name}/`} target="_blank" className="text-gray-400 hover:underline">{pr.reddit_submission_data.subreddit_name}</a>

                    {pr.model ? (
                      <div className="ml-auto px-2 py-1 rounded-md bg-gray-700 text-gray-200 text-xs">
                        {pr.model}
                      </div>
                    ) : ''}
                  </div>

                  <div className="text-gray-400 flex items-center text-sm">
                    <div className="flex space-x-1 flex-wrap">
                    <a href={`https://reddit.com${pr.reddit_submission_data.link}`} target="_blank" className="text-gray-400 hover:underline nowrap">"{pr.reddit_submission_data.title}"</a>
                    </div>
                  </div>

                  <div className="text-gray-200 flex">
                    <svg className="w-6 h-6 text-gray-700 mr-4 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                      <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
                    </svg>
                    <div dangerouslySetInnerHTML={{
                      __html: styleKeyword(pr.reddit_comment_data.body, pr.highlight, "font-semibold")
                    }} />
                  </div>

                  {pr.product && pr.product.model ? (
                    <div className="flex pl-10">
                      <div className="bg-gray-700 text-xs text-gray-200 font-semibold px-2 py-1 rounded-sm cursor-pointer hover:underline flex items-center"
                        onClick={() => {
                          // open up the view for this particular item in the make view
                          // clickedOnModel(pr.product)
                        }}
                      >
                        <div>{pr.product.make} / {pr.product.model}</div>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2 opacity-50">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>

                      </div>
                    </div>
                  ) : ''}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            {/*{make.products.length > 0 ? (
               <div className="text-sm flex space-x-2 mb-1">
                {make.products.map(p => (
                  <div key={p.id} className="px-2 py-1 rounded-md bg-gray-700 text-gray-200 flex items-center group-hover:bg-gray-600">
                    {p.model} <span className="font-semibold opacity-50 ml-2">{p.review_count}</span>
                  </div>
                ))}
              </div>
            ) : ''}*/}

            <div className="text-sm flex space-x-2 text-nowrap overflow-ellipsis truncate overflow-hidden">
              {make.reviews.filter(pr => pr.highlight).filter((pr, i) => i < numberOfHighlightsToShow).map((pr, i) => (
                <React.Fragment key={pr.id}>
                  <span className="flex-shrink-0 text-gray-300">"{capitalizeFirst(pr.highlight)}"</span>
                  {i < (make.reviews.length - 1) ? (
                    <span className="text-gray-300">·</span>
                  ) : ''}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MakeReviews;
